<div class="search_bar_content" id="serverSearchBar">
  <div class="top overflow-hidden bg-secondary">
    <button
      class="w-full bg-emerald-400 text-white py-3 rounded-lg font-bold"
      data-ph-capture-attribute-sc-event="server listing search prompt open"
      data-ph-capture-attribute-server-listing-search-prompt-action="open"
      (click)="openSearchPrompt()">
      Search Prompt
    </button>
  </div>

  <div class="filters overflow-hidden bg-secondary">
    <div class="mx-2 p-3 border-b border-gray-200 " *ngFor="let item of filterCategories" >
      <div class="flex justify-between cursor-pointer" (click)="toggleCategory(item)" tabindex="0">
        <div class="text-white flex gap-2 items-center">
          <lucide-icon name="{{item.icon}}" class="h-4 w-4 text-white"></lucide-icon>
          <div class="text-sm">{{item.name}}</div>
        </div>
        <div>
          <lucide-icon name="chevron-down" class="h-4 w-4 text-white"></lucide-icon>
        </div>
      </div>
      <div *ngIf="!item.collapsed" class="pt-4">
        <div *ngFor="let parameter of getParametersByCategory(item.category_id)" >
          <div *ngIf="getParameterType(parameter) !== 'checkbox'" class="flex gap-2 items-center">
            <div class="font-bold text-emerald-400 text-sm" id="filter_title_{{parameter.name}}">{{parameter.schema.title}}</div>
            <lucide-icon
              *ngIf="parameter.schema.description"
              name="info"
              class="h-4 w-4 text-gray-200"
              (mouseenter)="showTooltip($event, parameter.schema.description)"
              (mouseleave)="hideTooltip()">
            </lucide-icon>
          </div>
          <div *ngIf="getParameterType(parameter) === 'range'" class="mb-4 mt-4" >
            <div class="text-white text-sm"
                  *ngIf="parameter.modelValue && !(parameter.modelValue === parameter.schema.range_max && parameter.modelValue === parameter.schema.null_value)"
                  id="filter_range_value_{{parameter.name}}" >{{parameter.modelValue}} {{parameter.schema.unit}}</div>
            <div class="text-white text-sm"
                  *ngIf="parameter.modelValue === parameter.schema.range_max && parameter.modelValue === parameter.schema.null_value"
                  id="filter_range_value_{{parameter.name}}" > &#8734; {{parameter.schema.unit}}</div>
            <input id="filter_range_{{parameter.name}}" type="range"
                   min="{{parameter.schema.range_min}}" max="{{parameter.schema.range_max}}" step="{{getStep(parameter)}}"
                   data-ph-capture-attribute-sc-event="server listing filter"
                   data-ph-capture-attribute-server-listing-filter-type="range"
                   [attr.data-ph-capture-attribute-server-listing-filter-id]="parameter.name"
                   [attr.data-ph-capture-attribute-server-listing-filter-category]="item.category_id"
                   [(ngModel)]="parameter.modelValue"
                   (ngModelChange)="valueChanged(parameter)"
                   class="w-full h-2 accent-emerald-400 bg-gray-200 rounded-lg cursor-pointer range-sm">
          </div>
          <div *ngIf="getParameterType(parameter) === 'number'" class="mb-4 mt-4 relative" >
            <input type="number" step="{{getStep(parameter)}}"
                   id="filter_number_{{parameter.name}}"
                   data-ph-capture-attribute-sc-event="server listing filter"
                   data-ph-capture-attribute-server-listing-filter-type="number"
                   [attr.data-ph-capture-attribute-server-listing-filter-id]="parameter.name"
                   [attr.data-ph-capture-attribute-server-listing-filter-category]="item.category_id"
                   [(ngModel)]="parameter.modelValue"
                   (ngModelChange)="valueChanged()"
                   min="{{parameter.schema.range_min || 0}}"
                   class="w-full h-8 accent-emerald-400 bg-gray-200 rounded-lg cursor-pointer range-sm pr-8">
            <span *ngIf="parameter.schema.unit" class="unit text-gray-600">{{parameter.schema.unit}}</span>
          </div>
          <div *ngIf="getParameterType(parameter) === 'price'" class="mb-4 mt-4 relative" >
            <input type="number" step="{{getStep(parameter)}}"
                   id="filter_price_{{parameter.name}}"
                   data-ph-capture-attribute-sc-event="server listing filter"
                   data-ph-capture-attribute-server-listing-filter-type="number"
                   [attr.data-ph-capture-attribute-server-listing-filter-id]="parameter.name"
                   [attr.data-ph-capture-attribute-server-listing-filter-category]="item.category_id"
                   [(ngModel)]="parameter.modelValue"
                   (ngModelChange)="valueChanged()"
                   class="w-full h-8 accent-emerald-400 bg-gray-200 rounded-lg cursor-pointer range-sm pr-8">
            <span *ngIf="selectedCurrency.slug" class="unit text-gray-600">{{selectedCurrency.slug}}</span>
          </div>
          <div *ngIf="getParameterType(parameter) === 'text'" class="mb-4 mt-4" >
            <input type="text"
                   id="filter_text_{{parameter.name}}"
                   data-ph-capture-attribute-sc-event="server listing filter"
                   data-ph-capture-attribute-server-listing-filter-type="text"
                   [attr.data-ph-capture-attribute-server-listing-filter-id]="parameter.name"
                   [attr.data-ph-capture-attribute-server-listing-filter-category]="item.category_id"
                   [(ngModel)]="parameter.modelValue"
                   (ngModelChange)="valueChanged()"
                   class="w-full h-8 accent-emerald-400 bg-gray-200 rounded-lg cursor-pointer range-sm">
          </div>
          <div *ngIf="getParameterType(parameter) === 'checkbox'" class="flex gap-2 mb-4 mt-4" >
            <input type="checkbox"
                   id="filter_checkbox_{{parameter.name}}"
                   data-ph-capture-attribute-sc-event="server listing filter"
                   data-ph-capture-attribute-server-listing-filter-type="checkbox"
                   [attr.data-ph-capture-attribute-server-listing-filter-id]="parameter.name"
                   [attr.data-ph-capture-attribute-server-listing-filter-category]="item.category_id"
                   [(ngModel)]="parameter.modelValue"
                   (ngModelChange)="filterServers()"
                   class="focus:ring-emerald-400 accent-emerald-400 text-emerald-400 outline-none" >
            <div class="flex gap-2 items-center">
              <div class="text-white text-sm">
                {{parameter.schema.title}}
              </div>
              <lucide-icon
                *ngIf="parameter.schema.description"
                name="info"
                class="h-3 w-3 text-gray-200"
                (mouseenter)="showTooltip($event, parameter.schema.description)"
                (mouseleave)="hideTooltip()">
              </lucide-icon>
            </div>
          </div>
          <div *ngIf="getParameterType(parameter) === 'enumArray'" class="flex flex-col gap-2 mb-4 mt-4" >
            <div *ngFor="let enum of parameter.schema.enum" class="flex items-center gap-1">
              <input type="checkbox"
                     id="filter_enum_{{parameter.name}}"
                     data-ph-capture-attribute-sc-event="server listing filter"
                     data-ph-capture-attribute-server-listing-filter-type="checkbox"
                     [attr.data-ph-capture-attribute-server-listing-filter-id]="parameter.name"
                     [attr.data-ph-capture-attribute-server-listing-filter-category]="item.category_id"
                     [ngModel]="isEnumSelected(parameter, enum)"
                     (click)="selectEnumItem(parameter, enum)"
                     class="focus:ring-emerald-400 accent-emerald-400 text-emerald-400 outline-none" >
              <div class="text-white text-sm">{{enum?.value ? enum?.value : enum}}</div>
            </div>
          </div>
          <div *ngIf="getParameterType(parameter) === 'compliance_framework'" class="flex flex-col gap-2 mb-4 mt-4" >
            <div *ngFor="let enum of parameter.schema.enum" class="flex items-center gap-1">
              <input type="checkbox"
                     id="filter_enum_{{parameter.name}}"
                     data-ph-capture-attribute-sc-event="server listing filter"
                     data-ph-capture-attribute-server-listing-filter-type="checkbox"
                     [attr.data-ph-capture-attribute-server-listing-filter-id]="parameter.name"
                     [attr.data-ph-capture-attribute-server-listing-filter-category]="item.category_id"
                     [ngModel]="isEnumSelected(parameter, enum)"
                     (click)="selectEnumItem(parameter, enum)"
                     class="focus:ring-emerald-400 accent-emerald-400 text-emerald-400 outline-none" >
              <div class="text-white text-sm">{{getComplianceFrameworkName(enum)}}</div>
            </div>
          </div>
          <div *ngIf="getParameterType(parameter) === 'vendor'" class="flex flex-col gap-2 mb-4 mt-4" >
            <div *ngFor="let enum of parameter.schema.enum" class="flex items-center gap-1">
              <input type="checkbox"
                     id="filter_enum_{{parameter.name}}"
                     data-ph-capture-attribute-sc-event="server listing filter"
                     data-ph-capture-attribute-server-listing-filter-type="checkbox"
                     [attr.data-ph-capture-attribute-server-listing-filter-id]="parameter.name"
                     [attr.data-ph-capture-attribute-server-listing-filter-category]="item.category_id"
                     [ngModel]="isEnumSelected(parameter, enum)"
                     (click)="selectEnumItem(parameter, enum)"
                     class="focus:ring-emerald-400 accent-emerald-400 text-emerald-400 outline-none" >
              <div class="text-white text-sm">{{getVendorName(enum)}}</div>
            </div>
          </div>
          <div *ngIf="getParameterType(parameter) === 'storage_id'" class="flex flex-col gap-2 mb-4 mt-4" >
            <div *ngFor="let enum of parameter.schema.enum" class="flex items-center gap-1">
              <input type="checkbox"
                     id="filter_enum_{{parameter.name}}"
                     data-ph-capture-attribute-sc-event="server listing filter"
                     data-ph-capture-attribute-server-listing-filter-type="checkbox"
                     [attr.data-ph-capture-attribute-server-listing-filter-id]="parameter.name"
                     [attr.data-ph-capture-attribute-server-listing-filter-category]="item.category_id"
                     [ngModel]="isEnumSelected(parameter, enum)"
                     (click)="selectEnumItem(parameter, enum)"
                     class="focus:ring-emerald-400 accent-emerald-400 text-emerald-400 outline-none" >
              <div class="text-white text-sm">{{getStorageName(enum)}}</div>
            </div>
          </div>
          <div *ngIf="getParameterType(parameter) === 'country'" class="mb-4 mt-4 relative flex flex-col gap-2" >
            <div *ngFor="let continent of continentMetadata" class="flex flex-col gap-3" >
                <div class="flex justify-between">
                  <div class="flex gap-2 items-center">
                    <input type="checkbox"
                           id="filter_continent_{{continent.continent}}"
                           data-ph-capture-attribute-sc-event="server listing filter"
                           data-ph-capture-attribute-server-listing-filter-type="checkbox"
                           [attr.data-ph-capture-attribute-server-listing-filter-id]="continent.continent"
                           data-ph-capture-attribute-server-listing-filter-category="continent"
                           [ngModel]="continent.selected"
                           (click)="selectContinent(continent)"
                           class="focus:ring-emerald-400 accent-emerald-400 text-emerald-400 outline-none" >
                    <div class="text-white text-sm">{{continent.continent}}</div>
                  </div>
                  <lucide-icon name="chevron-down" class="h-4 w-4 text-white cursor-pointer" (click)="collapseItem(continent)"></lucide-icon>
                </div>
                <div *ngIf="!continent.collapsed"  class="flex flex-col gap-3 ml-4">
                  <div class="flex gap-2 align-center" *ngFor="let country of countriesByContinent(continent.continent)">
                    <input type="checkbox"
                           id="filter_country_{{parameter.name}}"
                           data-ph-capture-attribute-sc-event="server listing filter"
                           data-ph-capture-attribute-server-listing-filter-type="checkbox"
                           [attr.data-ph-capture-attribute-server-listing-filter-id]="country.country_id"
                           data-ph-capture-attribute-server-listing-filter-category="country"
                           [(ngModel)]="country.selected"
                           (ngModelChange)="filterServers()"
                           class="focus:ring-emerald-400 accent-emerald-400 text-emerald-400 outline-none" >
                    <div class="text-white text-sm">{{country.country_id | countryIdtoName}}</div>
                  </div>
                </div>
            </div>
          </div>
          <div *ngIf="getParameterType(parameter) === 'regions'" class="mb-4 mt-4 relative flex flex-col gap-2" >
            <div *ngFor="let vendor of regionVendorMetadata" class="flex flex-col gap-3" >
                <div class="flex justify-between">
                  <div class="flex gap-2 items-center">
                    <input type="checkbox"
                           id="filter_vendor_{{parameter.name}}"
                           data-ph-capture-attribute-sc-event="server listing filter"
                           data-ph-capture-attribute-server-listing-filter-type="checkbox"
                           [attr.data-ph-capture-attribute-server-listing-filter-id]="vendor.vendor_id"
                           data-ph-capture-attribute-server-listing-filter-category="vendor"
                           [ngModel]="vendor.selected"
                           (click)="selectRegionrVendor(vendor)"
                           class="focus:ring-emerald-400 accent-emerald-400 text-emerald-400 outline-none" >
                    <div class="text-white text-sm">{{vendor.name}}</div>
                  </div>
                  <lucide-icon name="chevron-down" class="h-4 w-4 text-white cursor-pointer" (click)="collapseItem(vendor)"></lucide-icon>
                </div>
                <div *ngIf="!vendor.collapsed"  class="flex flex-col gap-3 ml-4">
                  <div class="flex gap-2 align-center" *ngFor="let region of regionsByVendor(vendor.vendor_id)">
                    <input type="checkbox"
                           id="filter_region_{{parameter.name}}"
                           data-ph-capture-attribute-sc-event="server listing filter"
                           data-ph-capture-attribute-server-listing-filter-type="checkbox"
                           [attr.data-ph-capture-attribute-server-listing-filter-id]="region.api_reference"
                           data-ph-capture-attribute-server-listing-filter-category="region"
                           [(ngModel)]="region.selected"
                           (ngModelChange)="filterServers()"
                           class="focus:ring-emerald-400 accent-emerald-400 text-emerald-400 outline-none" >
                    <div class="text-white text-sm">{{region.name}}</div>
                    <lucide-icon *ngIf="region.green_energy" name="leaf" class="h-4 w-4 text-emerald-400"></lucide-icon>
                  </div>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Large Modal -->
<div id="large-modal"
    tabindex="-1"
    class="fixed top-0 left-0 right-0 z-50 hidden w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full">
  <div class="relative w-full max-w-xl max-h-full">
      <div class="relative bg-secondary rounded-lg shadow border border-solid border-emerald-400 " >
          <div class="flex items-center justify-between p-4 md:p-5 border-b rounded-t">
              <h3 class="text-xl font-medium text-white dark:text-white">
                  Search Prompt
              </h3>
              <button type="button"
                      data-ph-capture-attribute-sc-event="server listing search prompt close"
                      data-ph-capture-attribute-server-listing-search-prompt-action="close"
                      class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center"
                      (click)="closeModal(false)">
                <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                  <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                </svg>
                <span class="sr-only">Close modal</span>
              </button>
          </div>
          <div class="p-4 md:p-5 space-y-4 flex flex-col">
              <p class="text-base leading-relaxed text-gray-200">
                  Please type your server needs in free text and push submit.
              </p>
              <textarea
                rows="4"
                class="w-full accent-emerald-400 bg-gray-200 rounded-lg cursor-pointer range-sm focus:ring-emerald-400 focus:border-emerald-400"
                [(ngModel)]="freetextSearchInput">
              </textarea>
              <button
                data-ph-capture-attribute-sc-event="server listing search prompt process"
                data-ph-capture-attribute-server-listing-search-prompt-action="process"
                class="text-white bg-emerald-400 hover:bg-emerald-500 focus:ring-0 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center "
                (click)="submitFreetextSearch()"
                [disabled]="modalSubmitted"
                >
                Submit
              </button>
              <div *ngIf="modalResponse">
                <div class="text-white"> Suggested filter parameters </div>
                <div class="text-white font-medium text-sm flex flex-col pl-2">
                  <div *ngFor="let item of modalResponseStr" >
                    {{item}}
                  </div>
                </div>
              </div>
          </div>
          <div class="flex flex-col p-4 md:p-5 space-x-3 rtl:space-x-reverse border-t border-gray-200 rounded-b dark:border-gray-600">
              <button
                type="button"
                data-ph-capture-attribute-sc-event="server listing search prompt confirm"
                data-ph-capture-attribute-server-listing-search-prompt-action="confirm"
                [disabled]="!modalResponse"
                class="text-white bg-emerald-400 font-medium rounded-lg text-sm px-5 py-2.5 text-center
                 hover:bg-emerald-500 focus:ring-0 focus:outline-none
                 disabled:bg-gray-400 "
                (click)="closeModal(true)">
                  Confirm
              </button>
          </div>
      </div>
  </div>
</div>


<div id="tooltipDefault"
  #tooltipDefault
  style="opacity: 0;"
  class="fixed z-10 inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-secondary border border-solid border-emerald-400 rounded-lg">
  {{tooltipContent}}
</div>
